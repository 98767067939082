import React from "react";
import PageTitle from "../PageTitle";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import PageContainer from "../PageContainer";
import {
    Container,
    ImageContainer,
    Row,
    Column,
    Card,
    StyledImage,
    Title,
    StyledLink,
} from "./styles";
import useData from "./useData";

const History = () => {
    const list = useData();
    return (
        <PageContainer>
            <Breadcrumb>
                <BreadcrumbItem Link="/history" Text="History" />
            </Breadcrumb>
            <PageTitle>History</PageTitle>
            <Container>
                <Row>
                    {list.map(item => {
                        return (
                            <Column key={item.key}>
                                <StyledLink to={item.to}>
                                    <Card>
                                        <Title>{item.title}</Title>
                                        <ImageContainer>
                                            <StyledImage fluid={item.image} />
                                        </ImageContainer>
                                    </Card>
                                </StyledLink>
                            </Column>
                        );
                    })}
                </Row>
            </Container>
        </PageContainer>
    );
};

export default History;
