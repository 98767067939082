import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import History from "../components/history";

const HistoryPage = () => (
    <Layout>
        <SEO title="History" />
        <History />
    </Layout>
);

export default HistoryPage;
