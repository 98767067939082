import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import data from "./data";

const useData = () => {
    const img = useStaticQuery(graphql`
        fragment KoffiaPosterImg on File {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        query {
            koffia2024: file(
                relativePath: { eq: "koffiaHistory/KOFFIA2024.png" }
            ) {
                ...KoffiaPosterImg
            }
            koffia2023: file(
                relativePath: { eq: "koffiaHistory/KOFFIA2023.png" }
            ) {
                ...KoffiaPosterImg
            }
            koffia2022: file(
                relativePath: { eq: "koffiaHistory/KOFFIA2022.png" }
            ) {
                ...KoffiaPosterImg
            }
            koffia2021: file(
                relativePath: { eq: "koffiaHistory/KOFFIA2021.png" }
            ) {
                ...KoffiaPosterImg
            }
            koffia2020: file(
                relativePath: { eq: "koffiaHistory/KOFFIA2020.png" }
            ) {
                ...KoffiaPosterImg
            }
            koffia2019: file(
                relativePath: { eq: "koffiaHistory/KOFFIA2019.jpg" }
            ) {
                ...KoffiaPosterImg
            }
            koffia2018: file(
                relativePath: { eq: "koffiaHistory/KOFFIA2018.jpg" }
            ) {
                ...KoffiaPosterImg
            }
            koffia2017: file(
                relativePath: { eq: "koffiaHistory/KOFFIA2017.jpg" }
            ) {
                ...KoffiaPosterImg
            }
            koffia2016: file(
                relativePath: { eq: "koffiaHistory/KOFFIA2016.jpg" }
            ) {
                ...KoffiaPosterImg
            }
            koffia2015: file(
                relativePath: { eq: "koffiaHistory/KOFFIA2015.png" }
            ) {
                ...KoffiaPosterImg
            }
            koffia2014: file(
                relativePath: { eq: "koffiaHistory/KOFFIA2014.jpg" }
            ) {
                ...KoffiaPosterImg
            }
            koffia2013: file(
                relativePath: { eq: "koffiaHistory/KOFFIA2013.png" }
            ) {
                ...KoffiaPosterImg
            }
            koffia2012: file(
                relativePath: { eq: "koffiaHistory/KOFFIA2012.png" }
            ) {
                ...KoffiaPosterImg
            }
            koffia2011: file(
                relativePath: { eq: "koffiaHistory/KOFFIA2011.jpg" }
            ) {
                ...KoffiaPosterImg
            }
            koffia2010: file(
                relativePath: { eq: "koffiaHistory/KOFFIA2010.png" }
            ) {
                ...KoffiaPosterImg
            }
        }
    `);

    return data.map(item => ({
        ...item,
        image: img[item.key].childImageSharp.fluid,
    }));
};
export default useData;
