import Img from "gatsby-image";
import styled from "styled-components";
import { Link } from "gatsby";
import { breakpoints, colors } from "../../styles/variables";

export const Container = styled.div`
    padding: 2rem 1rem 0 1rem;
    display: flex;
    width: calc(100% + 2rem);
    margin-left: -1rem;

    @media (min-width: ${breakpoints.md}) {
        width: calc(100% + 3rem);
        margin-left: -1.5rem;
        padding: 2rem 0 0 0;
    }
`;

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

export const Column = styled.div`
    flex: 0 0 100%;
    padding: 0 0 3rem 0;

    @media (min-width: ${breakpoints.md}) {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 1.5rem 3rem 1.5rem;
    }

    @media (min-width: ${breakpoints.lg}) {
        flex: 0 0 33.333%;
        max-width: 33.333%;
    }
`;

export const Card = styled.div`
    width: 100%;
    border: solid 1px ${colors.gray};
    // max-width: 28rem;
    margin: 0 auto;
    transition: filter 0.3s transform 0.5s;
    &:hover {
        color: ${colors.primary};
        .gatsby-image-wrapper {
            transform: scale(1.1);
        }
    }
`;

export const ImageContainer = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
`;

export const StyledImage = styled(Img)`
    transition: transform 0.5s;
`;

export const Title = styled.div`
    padding: 1.2rem 1.5rem 0.6rem 1.5rem;
    font-size: 1.5rem;
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    color: #000;
`;
